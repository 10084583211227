@font-face {
  font-family: "SofiaPro";
  src: url("../fonts/SofiaProMedium.woff2") format("woff2");
  src: url("../fonts/SofiaProMedium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SofiaPro";
  src: url("../fonts/SofiaProSemiBold.woff2") format("woff2");
  src: url("../fonts/SofiaProSemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
