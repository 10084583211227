@import '../variables';
@import '../animations';

$loader-size: 110px;
$check-height: 55px;
$check-width: $check-height/2;
$check-left: 24px;
$check-top: 52px;
$check-thickness: 7px;
$check-color: white;

.animated-checkmark {
  .circle {
    width: $loader-size;
    height: $loader-size;
    border-radius: 50%;
    border: 7px solid #ffffff;
    background-color: $green;
    margin: 0 auto 40px;
    opacity: 0;
    animation-name: fadein;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
  }

  .checkmark {
    display: block;
    position: relative;

    &:after {
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: $check-thickness solid $check-color;
      border-top: $check-thickness solid $check-color;
      content: '';
      left: $check-left;
      top: $check-top;
      position: absolute;
      transform: scaleX(-1) rotate(135deg);
      opacity: 0;
      animation-name: checkmark;
      animation-duration: 800ms;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-delay: 500ms;
    }
  }

  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: $check-width;
      opacity: 1;
    }
    40% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
    100% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
  }
}
