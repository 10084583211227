$primary: #ff4640;
$primary-dark: #ff4640;
$blue: #7882f0;
$secondary: #ff8533;
$red: #ff4d6f;
$deep-blue: #0f1e3c;
$melon: #ff8256;
$soft-blue: #5699ff;
$grey-light: #f3eff5;
$green: #22c2a7;
$black: #0f1e3c;
$light-text: rgba(15, 30, 60, 0.5);
$main-background: rgba(242, 234, 247, 0.41);
$secondary-background: rgb(242, 234, 247);

$breakpoint-xs: 'max-width: 599px';
$breakpoint-sm: 'min-width: 600px';
$breakpoint-md: 'min-width: 960px';
$breakpoint-lg: 'min-width: 1280px';
$breakpoint-xl: 'min-width: 1920px';
