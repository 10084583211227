@import '../variables';

.stepper {
  .button-group {
    margin-top: 32px;
    margin-bottom: 16px;

    .button {
      cursor: initial;
      color: white !important;
    }
    .MuiButton-contained.Mui-disabled {
      color: white !important;
    }
    .primary {
      background-color: #102d5e !important;
    }
    .secondary {
      background-color: #2a74ed !important;
    }
    .outlined {
      background-color: rgba(0, 0, 0, 0.24) !important;
    }
  }
}
