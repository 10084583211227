@import '../variables';

.upload-button {
  z-index: 1;
  margin-bottom: 20px;
}

.file-upload-button {
  z-index: 1;
  width: 50%;
  height: 300px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  .file-input {
    display: none;
  }

  .image {
    img {
      max-width: 400px;
      max-height: 260px;
    }
    @media ($breakpoint-xs) {
      img {
        max-width: 200px;
        max-height: 130px;
      }
    }
  }

  .label {
    margin-top: 8px;
    font-size: 20px;
    font-weight: 600;
    opacity: 0.51;
    color: $black;
    text-transform: uppercase;
  }

  &.upload-success {
    .image,
    .label {
      opacity: 1;
    }
  }

  .progress-view {
    display: flex;
    flex-direction: column;
    align-items: center;

    .label {
      font-size: 12px;
      font-weight: 500;
      color: white;
      border-radius: 15px;
      background-color: $blue;
      padding: 7px 35px;
      opacity: 1;
      margin-bottom: 20px;
    }

    .progress-bar {
      width: 135px;
      height: 6px;
      background: rgba(15, 30, 60, 0.22);
      border-radius: 3px;
    }

    .progress-indicator {
      width: 0;
      height: 6px;
      border-radius: 3px;
      background: $blue;
    }
  }

  .delete-icon {
    position: absolute;
    color: red;
    top: 6px;
    right: 6px;
    .icon {
      z-index: 100;
      font-size: 40px;
    }
  }

  .checkmark-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    background: #22c2a7;
    opacity: 0.5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: fadein;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  @media (max-width: 800px) {
    width: 70%;
    height: 300px;

    .label {
      margin-top: 8px;
      font-size: 16px;
    }
  }

  @media ($breakpoint-xs) {
    width: 90%;
    height: 200px;

    .label {
      margin-top: 8px;
      font-size: 16px;
    }
  }
}
