@import 'fonts';
@import 'variables';
@import 'utils';

@import 'components/steppers';
@import 'components/animated-checkmark';
@import 'components/upload-button';
@import 'pages/stepform';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'SofiaPro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

.app-bar {
  .logo {
    max-width: 100px;
    max-height: 100px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .title {
    margin-left: 16px;
    font-weight: 900;
  }
}
