.flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-size {
  width: 100vw;
  height: 100vh;
}

.flex-even {
  display: flex;
  justify-content: space-evenly;

  > div {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}

.space-between {
  display: flex;
  justify-content: space-between;

  > div {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}

.flex-justify-between {
  display: flex;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
  }
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}
